<template>
  <div>
    <div v-if="creditInfo != null" class="fixed-credit-info">
      <div
        v-show="!showCreditInfo"
        class="icon-show-credit"
        :class="showCreditInfo ? 'hide' : ''"
        @click="showCreditInfo = !showCreditInfo"
      >
        <div class="icon-credit">
          <b-img src="/images/icon-credit-info.png"></b-img>
        </div>
        <span class="mt-2 p-2">ข้อมูลเครดิต</span>
      </div>
    </div>
    <b-card
      v-if="creditInfo != null"
      class="fixed-credit-info-container"
      :class="showCreditInfo ? 'active' : ''"
    >
      <b-row class="title py-2">
        <b-col
          ><span class="title-text">ข้อมูลเครดิต</span>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="showCreditInfo = !showCreditInfo"
          >
            ×
          </button></b-col
        >
      </b-row>
      <b-row class="content pt-3">
        <b-col class="text-bold text-orange">บิลค้างชำระ</b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-list-group class="list-content">
            <b-list-group-item class="pt-2 pb-1"
              >จำนวน :
              <span
                v-if="parseInt(creditInfo.total_credit_bill) > 0"
                class="text-orange"
              >
                {{ parseInt(creditInfo.total_credit_bill).toLocaleString() }}
                บิล
              </span>
              <span v-else class="text-orange">
                - บิล
              </span>
            </b-list-group-item>
            <b-list-group-item class="pt-2 pb-1"
              >รวมมูลค่าบิล :
              <span
                v-if="parseFloat(creditInfo.total_credit_amount) > 0"
                class="text-orange"
              >
                {{
                  parseFloat(creditInfo.total_credit_amount).toLocaleString()
                }}
                ฿
              </span>
              <span v-else class="text-orange">
                - ฿
              </span></b-list-group-item
            >
          </b-list-group>
        </b-col>
      </b-row>
      <hr />
      <b-row class="content">
        <b-col class="text-bold text-danger">บิลเกินกำหนดชำระ</b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-list-group class="list-content">
            <b-list-group-item class="pt-2 pb-1"
              >จำนวน :
              <span
                v-if="parseInt(creditInfo.total_credit_bill_overdue) > 0"
                class="text-danger"
              >
                {{
                  parseInt(
                    creditInfo.total_credit_bill_overdue
                  ).toLocaleString()
                }}
                บิล
              </span>
              <span v-else class="text-danger">
                - บิล
              </span>
            </b-list-group-item>
            <b-list-group-item class="pt-2 pb-1"
              >รวมมูลค่าบิล :
              <span
                v-if="parseInt(creditInfo.total_credit_amount_overdue) > 0"
                class="text-danger"
              >
                {{
                  parseFloat(
                    creditInfo.total_credit_amount_overdue
                  ).toLocaleString()
                }}
                ฿
              </span>
              <span v-else class="text-danger">
                - ฿
              </span></b-list-group-item
            >
          </b-list-group>
        </b-col>
      </b-row>
      <b-row
        v-if="
          !(
            $route.name == 'profile' &&
            $route.query.page_active == 'credit_unpaid_bill'
          )
        "
      >
        <b-col class="text-center pt-3">
          <b-button class="btn-detail px-3 py-2" @click="goToUnpaidBillPage()"
            >ดูรายละอียด</b-button
          >
        </b-col>
      </b-row>
      <hr />
      <b-row class="limit">
        <b-col cols="6">
          <div class="btn-credit p-2">
            <div class="d-block text-center">
              <span class="ico-orange" rounded="circle"></span> Credit limit
            </div>
            <span class="d-block text-center text-orange text-large">
              {{ parseFloat(creditInfo.credit_amount_limit).toLocaleString() }}
            </span>
            <span class="d-block text-center text-unit"> THB</span>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="btn-credit p-2">
            <div class="d-block text-center">
              <span
                :class="
                  parseFloat(creditInfo.credit_amount_remaining) <= 0
                    ? 'ico-danger'
                    : 'ico-blue'
                "
                rounded="circle"
              ></span
              >วงเงินที่เหลือ
            </div>
            <span
              class="d-block text-center text-large"
              :class="
                parseFloat(creditInfo.credit_amount_remaining_realtime) <= 0
                  ? 'text-danger'
                  : 'text-blue'
              "
            >
              {{
                parseFloat(
                  creditInfo.credit_amount_remaining_realtime
                ).toLocaleString()
              }}
            </span>
            <span class="d-block text-center text-unit"> THB</span>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCreditInfo: false,
      isCrediTtype: 0,
      creditInfo: null
    }
  },
  mounted() {
    this.$bus.$on('getUserDataLogin', (userLoginData) => {
      this.isCreditType = userLoginData.credit_type_payment
      this.creditInfo = userLoginData.credit_used_info
    })
  },
  methods: {
    goToUnpaidBillPage() {
      window.location.href = '/profile/?page_active=credit_unpaid_bill'
    }
  }
}
</script>

<style lang="scss" scope>
.fixed-credit-info {
  z-index: 3;
  position: fixed;
  top: 250px;
  right: 10px;
  visibility: visible;
  opacity: 1;
  transition-duration: 0.5s;

  @media (max-width: 767px) {
    top: auto;
    bottom: 50px;
  }

  &.hide {
    right: -80px;
    visibility: hidden;
    opacity: 0;
  }

  .icon-show-credit {
    display: block;
    text-align: center;
    cursor: pointer;
    .icon-credit {
      color: #fff;
      display: block;
      height: 69px;
      width: 69px;
      position: relative;
      text-align: center;
      line-height: 69px;
      margin: 0 auto;
      z-index: 2;
    }
    span {
      position: absolute;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      visibility: hidden;
      transition-duration: 0.5s;
      z-index: 1;
      opacity: 0;
      top: 8px;
      right: 80px;
      width: 120px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 10px;
    }

    &:hover > span {
      visibility: visible;
      right: 70px;
      opacity: 1;
    }
  }
}

.fixed-credit-info-container {
  z-index: 4;
  position: fixed;
  top: 250px;
  right: -350px;
  width: 350px;
  box-shadow: 0 2px 4px 0 #999999;
  visibility: hidden;
  transition-duration: 0.5s;
  opacity: 0;

  @media (max-width: 767px) {
    top: auto;
    bottom: -2px;
  }

  &.active {
    visibility: visible;
    right: 10px;
    opacity: 1;

    @media (max-width: 575px) {
      width: 100%;
      right: 0px;
    }
  }

  .title {
    height: 56px;
    background: #f88601;
    border-radius: 5px 5px 0px 0px;
    .title-text {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
    }
    .close {
      font-weight: 300;
      padding: 1rem;
      margin: -0.75rem -1rem -1rem auto;
      color: #ffffff;
      margin: -1.25rem -1rem -1rem auto;
      font-size: 40px;
      opacity: 0.75;
    }
  }
  .btn-credit {
    background: #ffffff;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .card-body {
    padding: 0rem 0.85rem 1rem 0.85rem;
  }

  .ico-orange {
    display: inline-block;
    float: left;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #f5a623;
  }

  .ico-blue {
    display: inline-block;
    float: left;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #0098ef;
  }

  .ico-danger {
    display: inline-block;
    float: left;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #dc3545;
  }

  .text-large {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
  }

  .text-orange {
    color: #f5a623;
  }

  .text-blue {
    color: #0098ef;
  }

  .text-unit {
    font-weight: 500;
    font-size: 10px;
    color: #999999;
  }

  .text-bold {
    font-weight: 500;
  }

  .list-group-item {
    border: none;
  }

  .btn-detail {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: linear-gradient(90deg, #0098ef 0%, #00c9f8 100%);
    border: none;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
</style>
