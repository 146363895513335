<template>
  <div></div>
</template>

<script>
const config = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  head() {
    return {
      script: [
        {
          src: config.freskdesk_script_link,
          type: 'text/javascript',
          chat: 'true'
        }
      ]
    }
  }
}
</script>
